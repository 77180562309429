import { createRoot } from 'react-dom/client';
import './redirectFromOldDomains';
import App from './App';
import React from 'react';

const container = document.getElementById('root');
const root = container && createRoot(container);

root?.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
