import { FunctionComponent, useState } from 'react';
import { APP_VERSION } from 'utils/constants';

import { getAppVersion } from 'service/version.service';
import { delay } from 'lodash';
import { VersionGuard } from 'components/common/VersionGuard/VersionGuard';

export const AppVersionNotification: FunctionComponent = () => {
  const [appVersion, setAppVersion] = useState<string>(APP_VERSION);

  const handleAppVersionChange = async () => {
    const response = await getAppVersion();
    if (response) {
      setAppVersion(response.version);
    }
    delay(() => {
      setAppVersion(APP_VERSION);
    }, 30000);
  };

  const isVersionMismatch = appVersion !== APP_VERSION;

  return (
    <VersionGuard
      onCheckVersion={handleAppVersionChange}
      isVersionMismatch={isVersionMismatch}
      autoHideDuration={10000}
    />
  );
};
