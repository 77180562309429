import { Suspense, PropsWithChildren } from 'react';
import { Route, RouteProps } from 'react-router-dom';

import { ViewLoader } from '../ViewLoader/ViewLoader';

export const SuspenseRoute = ({ children, ...routeProps }: PropsWithChildren<RouteProps>) => (
  <Route {...routeProps}>
    <Suspense fallback={<ViewLoader />} children={children} />
  </Route>
);
