import { FunctionComponent } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Router, Route } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { history } from 'appHistory';
import { initSentry } from 'service/sentry.service';
import store, { persistor } from 'store';

import { enUsDateLocale } from 'translations/en-US';
import { QueryParamProvider } from 'use-query-params';
import { queryClient } from 'queryClient';
import { Routes } from 'Routes';
import { useAuth } from '@kitopi-com/auth';
import { authConfig } from 'auth.config';
import IframeProvider from 'salesforceIntegrations/IframeProvider';
import { LicenseInfo } from '@mui/x-license-pro';
import { featureTogglesEnvironments, MUI_X_LICENSE_KEY } from 'utils/constants';
import { FeatureTogglesProvider } from 'context/FeatureTogglesContext/FeatureTogglesProvider';
import { AppSettingsContextProvider } from 'context/AppSettings';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

LicenseInfo.setLicenseKey(MUI_X_LICENSE_KEY);

initSentry();

const App: FunctionComponent = () => {
  useAuth(authConfig);

  return (
    <FeatureTogglesProvider environments={featureTogglesEnvironments}>
      <AppSettingsContextProvider>
        <IframeProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enUsDateLocale}>
            <PersistGate loading={null} persistor={persistor}>
              <QueryClientProvider client={queryClient}>
                <Provider store={store}>
                  <Router history={history}>
                    <QueryParamProvider ReactRouterRoute={Route}>
                      <Routes />
                    </QueryParamProvider>
                  </Router>
                </Provider>

                <ReactQueryDevtools buttonPosition="bottom-left" />
              </QueryClientProvider>
            </PersistGate>
          </LocalizationProvider>
        </IframeProvider>
      </AppSettingsContextProvider>
    </FeatureTogglesProvider>
  );
};

export default App;
