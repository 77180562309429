import { Button } from '@mui/material';
import { OptionsObject, useSnackbar } from 'notistack';
import { useCallback, useEffect } from 'react';

interface VersionGuardProps extends OptionsObject {
  readonly buttonText?: string;
  readonly message?: string;
  readonly onCheckVersion: () => void;
  readonly isVersionMismatch: boolean;
}

export const VersionGuard = ({
  buttonText = 'Refresh',
  message = `Looks like we've just pushed another update. \nLet’s hit refresh to get you the latest version from us!`,
  onCheckVersion,
  isVersionMismatch,
  ...rest
}: VersionGuardProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const action = useCallback(
    () => (
      <Button
        color="inherit"
        variant="text"
        data-testid="version-guard-reload-button"
        size="small"
        onClick={() => window.location.reload()}
      >
        {buttonText}
      </Button>
    ),
    [buttonText]
  );

  const showSnackBar = useCallback(() => {
    enqueueSnackbar(<div data-testid="version-guard">{message}</div>, {
      variant: 'info',
      autoHideDuration: 6000,
      action,
      style: {
        whiteSpace: 'pre-line',
      },
      ...rest,
    });
  }, [action, enqueueSnackbar, message, rest]);

  useEffect(() => {
    isVersionMismatch && showSnackBar();
  }, [isVersionMismatch, showSnackBar]);

  useEffect(() => {
    window.addEventListener('focus', onCheckVersion);
    return () => {
      window.removeEventListener('focus', onCheckVersion);
    };
  }, [onCheckVersion]);

  return null;
};
