import { FunctionComponent, lazy } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { oldTheme } from 'utils/theme/oldTheme';
import ErrorBoundary from 'components/ErrorBoundary';
import { AppWrapper } from 'components/AppWrapper/AppWrapper';
import { AppVersionNotification } from 'components/AppVersionNotification/AppVersionNotification';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { ThemeProvider as KitopiUiThemeProvider } from '@kitopi-com/ui';
import { SnackbarProvider } from 'notistack';
import { GlobalStyles, GlobalCCPStyles } from './styles';
import { SnackbarUtilsConfigurator } from 'utils/notifications';
import { DialogUtilsConfigurator } from 'utils/dialog';
import ModalProvider from 'mui-modal-provider';
import { useAuth } from '@kitopi-com/auth';
import { authConfig } from 'auth.config';
import { FeatureToggles } from 'components/FeatureToggles/FeatureToggles';
import { SuspenseRoute } from 'components/common/SuspenseRoute/SuspenseRoute';
import { ConfirmationDialogProvider } from 'components/common/ConfirmationDialog/ConfirmationDialogProvider';
import { useAppTheme } from 'hooks/useAppTheme';

const AppRoutes = lazy(() => import('./AppRoutes'));

export const Routes: FunctionComponent = () => {
  const { isInitialized: isAuthInitialized } = useAuth(authConfig);
  const { theme } = useAppTheme();
  return (
    <>
      <GlobalStyles />

      <Switch>
        <Redirect exact from="/" to="/app" />
        <SuspenseRoute path="/app/">
          <KitopiUiThemeProvider theme={theme}>
            <MuiThemeProvider theme={oldTheme}>
              <SnackbarProvider
                preventDuplicate
                style={{ pointerEvents: 'all', marginBottom: '6px', marginTop: '6px' }}
              >
                <ConfirmationDialogProvider>
                  <ModalProvider>
                    <SnackbarUtilsConfigurator />
                    <DialogUtilsConfigurator />
                    <FeatureToggles />
                    <GlobalCCPStyles />
                    <ErrorBoundary>
                      <AppWrapper isAuthInitialized={isAuthInitialized}>
                        <AppRoutes />
                      </AppWrapper>
                    </ErrorBoundary>
                    {!process.env.DEV_MODE && <AppVersionNotification />}
                  </ModalProvider>
                </ConfirmationDialogProvider>
              </SnackbarProvider>
            </MuiThemeProvider>
          </KitopiUiThemeProvider>
        </SuspenseRoute>
      </Switch>
    </>
  );
};
