interface VersionObject {
  version: string;
}

export const getAppVersion = (): Promise<VersionObject> => {
  return fetch('/version.json', {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  }).then(response => {
    return response.json();
  });
};
